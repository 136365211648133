<template>
  <div id="login" />
</template>

<script>
/**
 * LogIn
 *
 * This component calls the global store to begin the
 * OAuth implicit authentication flow (redirects to the
 * auth server).
 */
export default {
  name: 'LogIn',
  created () {
    this.$store.dispatch('auth/login')
  }
}
</script>
